.price-card {
  background: #ffffff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  padding: 15px;
  border-radius: 6px;
  border-top: 2px solid #ffbb2c;
}
.playing-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.main-price {
  font-size: 1.875rem;
  margin-right: 0.5rem;
  letter-spacing: 0.20000000298023224px;
}
.second-price {
  margin-right: 0.5rem;
  letter-spacing: 0.20000000298023224px;
}
.btn-buy {
  letter-spacing: 0.20000000298023224px;
}
