.banner-title {
  font-size: 24px;
  font-weight: 500;
  color: black;
}
.banner-details {
  font-size: 15px;
  line-height: 1.75rem;
}
.eduction-title-home {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
}
#hero {
  width: 100%;
  height: 87vh;
  /* background-image: url('../../../../Images/web-cover-update-final.jpg'); */
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  /* padding-top: 110px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.title-gradient__gradient {
  background-color: #fff;
  background-image: linear-gradient(45deg, #f1a41a, #f05a28);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -moz-background-clip: text;
  -moz-text-fill-color: rgba(0, 0, 0, 0);
}
@media (max-width: 575px) {
  #hero {
    padding-top: 10px;
    height: 90vh;
  }
}

@media (max-width: 991px) {
  #hero {
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  #hero {
    padding-top: 30px;
  }
}

.banner-card-border {
  border: 2px double transparent;
  border-radius: 12px;
  background-image: linear-gradient(#fefaef, #d3f6eb),
    linear-gradient(
      rgba(255, 109, 109, 0.92),
      rgba(255, 225, 146, 0.72),
      rgb(7, 188, 12, 0.61)
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.hero-right-image {
  width: 800px;
}
/* Responsive  css file */

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-right-image {
    width: 470px;
  }
}
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hero-right-image {
    width: 500px;
  }
}
@media (min-width: 1199px) and (max-width: 1399px) {
  .hero-right-image {
    width: 510px;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .hero-right-image {
    width: 550px;
  }
}
