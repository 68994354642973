.kits-course {
  padding-top: 30px;
}
.skill-container {
  background-image: url('../../../../Images/academic_program_bg2.jpg');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.text-color {
  color: #fff !important;
}

.kits-box {
  cursor: pointer;
  /* box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1); */
  transition: all ease-in-out 0.4s;
  border: 1px solid #fff;
}
.kits-box:hover {
  border: 1px solid #1bb57b;
}
.kits-box:hover {
  transform: translateY(-10px);
}
.kits-title {
  font-size: 22px;
  margin: 0px 0 12px 0;
}
.kits-box:hover .kits-title {
  color: #1bb57b;
}
.kits-details {
  line-height: 24px;
  font-size: 15px;
  margin-bottom: 0;
  color: gray;
}

/* Card new design */
.course-card {
  background: #f5f5f5;
  /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06); */
  border-radius: 10px;
  height: 100%;
  transition: all ease-in-out 0.4s;
}

.course-card .course-card-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0px 0px;
}
.course-card .course-card-img img {
  height: 100%;
  width: 100%;
  transition: 0.6s;
}

.course-card .course-card-img::before {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  transition: 0.6s;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  transform: skewX(-25deg);
}
.course-card:hover .course-card-img::before {
  animation: shine 0.75s;
}
.course-card:hover .course-card-img img {
  transform: scale(1.1);
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.course-card .thumbnail-content {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
}
.course-card .course-card-body {
  padding: 25px 20px;
}
.kits-title-2 {
  font-size: 21px;
  margin: 0px 0 10px 0;
}
