@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Oxygen+Mono&family=Roboto:wght@100;300;400;500;700&display=swap');

body {
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Hind Siliguri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Global Colors */
:root {
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --default-color: #212529;
  --default-color-rgb: 33, 37, 41;
  --primary-color: #1bb57b;
  --primary-color-rgb: 27, 181, 123;
  --secondary-color: #ffbb2c;
  --secondary-color-rgb: 255, 187, 44;
  --contrast-color: #ffffff;
  --contrast-color-rgb: 255, 255, 255;
  --text-primary-color: #111827;
  --text-secondary-color: #4b5563;
  --section-Background-color: #f4f7fd;
  --main-button-hover-color: #0b6a9c;
}
.primary-color {
  color: var(--primary-color);
}
.primary-secondary {
  color: var(--secondary-color);
}
.text-primary-color {
  color: var(--text-primary-color);
}
.text-secondary-color {
  color: var(--text-secondary-color);
}
/*--------------------------------------------------------------
# Section Title
--------------------------------------------------------------*/

.section-header {
  text-align: center;
  padding-bottom: 40px;
}
.section-header h4 {
  font-family: var(--Bangla-font);
  font-weight: 600;
  font-size: 18px;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}
.section-header h2 {
  font-family: var(--Bangla-font);
  font-size: 26px;
  font-weight: 600;
  line-height: 38px;
  position: relative;
  color: var(--text-primary-color);
  margin: 0 auto 0 auto;
}

.section-header p {
  margin: 0 auto 0 auto;
  font-family: var(--Bangla-font);
  color: var(--text-secondary-color);
  line-height: 26px;
  letter-spacing: 0.20000000298023224px;
  font-size: 16px;
}

@media (min-width: 1199px) {
  .section-header p {
    max-width: 60%;
  }
  .section-header h2 {
    max-width: 53%;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
  }
}
.title-gradient {
  background-color: #fff;
  background-image: linear-gradient(45deg, #1bb57b, #07bc0c);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -moz-background-clip: text;
  -moz-text-fill-color: rgba(0, 0, 0, 0);
}
.header-shadow {
  box-shadow: 0 2px 15px rgba(25, 119, 204, 0.1);
}
.ds-shadow {
  box-shadow: 0 2px 15px rgba(25, 119, 204, 0.1) !important;
}
/*--------------------------------------------------------------
# Custom button design
--------------------------------------------------------------*/
.custom-button {
  border-radius: 0.25rem;
  text-transform: uppercase;
  -webkit-clip-path: polygon(
    0 0,
    0 0,
    100% 0,
    100% 0,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    0 0,
    100% 0,
    100% 0,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 100%
  );
  /* background-image: linear-gradient(90deg, #1bb57b, #07bc0c); */
  background-image: linear-gradient(90deg, #8bc34a, #07bc0c);
  border: 0 solid;
  overflow: hidden;
  cursor: pointer;
}
/* dashboard section */
.dashboard-section {
  padding: 2rem;
  overflow: hidden;
}
.dashboard-box {
  background: #fff;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);
}
.welcome-box {
  /* background: linear-gradient(180deg,rgba(187,196,245,.6),rgba(183,193,244,.6) 48.44%,rgba(175,184,239,.6) 71.87%,rgba(172,182,237,.6)); */
  background: linear-gradient(
    180deg,
    rgb(187 245 201 / 60%),
    rgb(187 244 183 / 60%) 48.44%,
    rgb(175 239 178 / 60%) 71.87%,
    rgb(178 237 172 / 60%)
  );
}
