.videos-card-title {
  background: #ffffff;
  /* box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 30px;
  border-top: 2px solid #ffbb2c;
  border-radius: 6px;
}
.videos-title {
  border-bottom: 1px solid #dde0e3;
  margin-bottom: 15px;
}
.videos-title h3 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 0.20000000298023224px;
  padding-bottom: 6px;
}
.videos-class-title {
  line-height: normal;
  letter-spacing: 0.20000000298023224px;
}

.btn-danger-soft {
  height: 32px;
  width: 32px;
  line-height: 30px;
  background-color: rgba(214, 41, 62, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play-icons {
  color: #d6293e;
}
.btn-danger-soft:hover {
  background-color: #d6293e;
}
.btn-danger-soft:hover .play-icons {
  color: #fff;
}
.videos-name-main:hover .btn-danger-soft {
  background-color: #d6293e;
}
.videos-name-main:hover .play-icons {
  color: #fff;
}

.hr-style {
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}
