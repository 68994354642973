.Level-items {
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0px 0px 0px 0px;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  height: 100%;
}
.bottom-shapes {
  left: 0;
  bottom: -10px;
  position: absolute;
  z-index: -1;
  width: 100%;
}
.shapes-raps {
  margin: 0px 20px 0px 20px;
  background: #fff;
  padding: 30px 0px 30px 0px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}
.level-image {
  border-radius: 20px 20px 0px 0px;
  height: 100%;
}
