.home-contact {
  overflow: hidden;
  background: #f7f5f2;
  padding: 50px 0;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.home-contact::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  filter: brightness(0) saturate(100%) invert(90%) sepia(23%) saturate(5531%)
    hue-rotate(343deg) brightness(101%) contrast(94%);
  background: url('../../../../Images/subscribe-banner.svg');
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-contact .home-content {
  position: relative;
  z-index: 2;
}
.home-contact .shapes-01 {
  position: absolute;
  z-index: -1;
  transform: translateX(-9.56px) translateY(7.16px) translateZ(0px);
  top: 109px;
  left: 0px;
}
.home-contact .shapes-02 {
  position: absolute;
  z-index: -1;
  transform: translateX(0.52px) translateY(-2.06px) translateZ(0px);
  bottom: 29px;
  left: 85px;
}
.home-contact .shapes-03 {
  transform: translateX(7.92px) translateY(-1.94px) translateZ(0px);
  top: -61px;
  right: -10px;
  z-index: 0;
  position: absolute;
}
.home-contact .shapes-04 {
  transform: translateX(2.44px) translateY(1.86px) translateZ(0px);
  top: -126px;
  right: 50px;
  position: absolute;
  z-index: -1;
}
