.theme-primary-color {
  color: #1e293b;
}
.theme-secondary-color {
  color: #ffbb2c;
  /* color: #1bb57b; */
}
.theme-primary-color-bg {
  background-color: #1bb57b;
}
.theme-secondary-color-bg {
  background-color: #ffbb2c;
}

/* .text-font {
  font-family: "Oxygen Mono", monospace;
} */

.footer-color {
  color: #0a033c;
}
.footer-color2 {
  color: #0b7077;
}
.footer-color-bg {
  background-color: #0b7077;
}
.font-size {
  font-size: 34px;
  line-height: normal;
  font-weight: 800;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background-color: rgba(4, 9, 30, 0.5);
}

.background-color {
  /* background-color: #8ec5fc; */
  background-image: linear-gradient(
    358deg,
    #bbf7d0 50%,
    #ffffff 100%
  );
}

.card-shadow {
  border: 7px solid white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

/* #C6FFDD */

/* TestimonialCard background css */
/* .testimonial-bg {
  
background: linear-gradient(to top, #c9ffbf, #ffafbd);
} */
