.testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 0 30px;
  margin: 15px 5px;
  text-align: center;
  border-radius: 10px;
  min-height: 271px;
  /* background-color: white;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08); */
  /* background: white; */
  /* background: linear-gradient(
    to left,
    #cfdef3,
    #e0eafc
  ); */
  background: #fff;
  border: 2px solid #c7cbd3;
  box-shadow: 0 4px 8px -8px rgba(8, 31, 77, 0.2);
}

.testimonial-item .testimonial-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid #f5c310;
  margin: 0 auto;
  object-fit: cover;
}
.testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #124265;
}

.testimonial-item h4 {
  font-size: 14px;
  color: rgb(117, 117, 117);
  margin: 0;
}

.testimonial-item .quote-icon-left,
.testimonial-item .quote-icon-right {
  color: #f5c310;
  font-size: 26px;
}

.testimonial-item .quote-icon-left {
  display: inline-block;
  left: -10px;
  position: relative;
}

.testimonial-item .quote-icon-right {
  display: inline-block;
  right: -10px;
  position: relative;
  top: 10px;
}

.testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  font-size: 15px;
}
.swiper-pagination {
  margin-top: 10px;
  position: relative;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #1bb57b;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1bb57b;
}
.testimonial-bg {
  background: #fff;

  overflow: hidden;

  padding: 40px 0px;
}
.testimonial-container {
  position: relative;
  z-index: 1;
}
.testimonial-bg .map-shapes {
  position: absolute;
  bottom: 20px;
  left: 45%;
  transform: translateX(-50%);
  opacity: 1;
  z-index: -1;
}
.testimonial-bg .shape-image {
  position: absolute;
  z-index: -1;
}
.testimonial-bg .shape-image-4 {
  bottom: -25px;
  right: -218px;
}
.testimonial-bg .shape-image-3 {
  bottom: 16px;
  left: -46px;
}
.testimonial-bg .shape-image-2 {
  right: -150px;
  top: 32px;
}
.testimonial-bg .shape-image-1 {
  left: -61px;
  top: 69px;
}
