.call-image img {
  width: 25px;
  height: 25px;
}
.footer-area {
  background-color: rgb(27 181 123 / 20%);
  position: relative;
  z-index: 1;
}
.footer-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  animation: imageChange 1s linear 1s infinite;
  background-image: url(../../../Images/footer-shape1.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer-area::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  animation: imageChangeTwo 1s linear 1s infinite;
  background-image: url(../../../Images/footer-shape2.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@keyframes imageChange {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes imageChangeTwo {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
