.client-item {
  box-sizing: content-box;
  padding: 30px 30px 0 30px;
  margin: 30px 15px;
  text-align: center;
  /* border-radius: 6px; */
  min-height: 271px;
  background-color: white;
  box-shadow: 0px 6px 15px rgba(7, 78, 206, 0.1);
  border-radius: 10px;
  /* box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08); */
}

.client-item .client-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid #ffc107a1;
  margin: 0 auto;
  object-fit: cover;
}
.client-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #124265;
}

.client-item h4 {
  font-size: 14px;
  color: rgb(117, 117, 117);
  margin: 0;
}

.client-item .quote-icon-left,
.client-item .quote-icon-right {
  color: #f5c310;
  font-size: 26px;
}

.client-item .quote-icon-left {
  display: inline-block;
  left: -10px;
  position: relative;
}

.client-item .quote-icon-right {
  display: inline-block;
  right: -10px;
  position: relative;
  top: 10px;
}

.client-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  font-size: 15px;
}
