.partner-logo {
  width: 155px;
  /* object-fit: fill; */
}
#partner {
  background: #fff;
  /* background-image: url(https://i.ibb.co/M1j9W1d/activities-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 40px 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.proud-partners-images {
  background-color: rgba(26, 182, 157, 0.07);
  border-style: solid;
  border-width: 0;
  border-color: #edeff2;
  /* box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05); */
  margin: 1px;
  padding: 10px 50px;
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;
  border-bottom: 4px solid transparent;
  border-radius: 10px;
  filter: grayscale(100%);
  transition: all;
  transition-duration: 0.4s;
}
.proud-partners-images:hover {
  filter: grayscale(0%);
}
.vector-shape11 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
