#loginBanner {
  background-image: url('../../Images/login-banner.png');
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}
#loginBanner::before {
  content: '';
  position: absolute;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.login-background-color {
  /* background-image: linear-gradient(25deg, #075942, #346b40, #517e3c, #4CAF50); */
  background-image: linear-gradient(25deg, #052541, #1b4b4b, #247554, #24a15b);
}
