.info-item {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  padding: 20px 20px 30px 20px;
  border-radius: 6px;
  background-color: white;
  text-align: center;
}
.info-item .contact-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  line-height: 0;
  border-radius: 50%;
  border: 2px dotted #ffc107;
}

.info-item h3 {
  font-size: 20px;
  color: #6c757d;
  font-weight: 700;
  margin: 10px 0 4px 0px;
}

.info-item p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.contact-info {
  padding: 50px 0px;
}
