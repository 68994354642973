.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.top-btn {
  padding: 8px;
  right: 1.25rem;
  z-index: 999;
  bottom: 1.5rem;
  color: #fff;
  display: flex;
  cursor: pointer;
  position: fixed;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(226.1deg, rgb(8, 206, 51) 0%, rgb(20, 156, 82) 101.24%);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.top-btn-icon {
  font-size: 22px;
  animation: gototop 1.2s linear infinite alternate-reverse;
}

@keyframes gototop {
  0% {
    transform: translateY(-0.2rem);
  }
  100% {
    transform: translateY(0.3rem);
  }
}

@media screen and (max-width: 768px) {
  .top-btn {
    width: 50px;
    height: 50px;
    right: 10%;
    bottom: 5%;
  }
  .top-btn--icon {
    font-size: 15px;
  }
}
