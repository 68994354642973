.videosPlaying-section {
  padding: 60px 0px;
  background: #EDEEF3;
}
.media-player {
  /* Player CSS variables (defaults shown). */
  --media-font-family: sans-serif;
  --media-controls-color: #f5f5f5;
  --media-focus-ring: 0 0 0 3px rgb(78 156 246);
}

.vds-button {
  /* CSS variables available for customization (defaults shown). */
  --media-button-color: var(--media-controls-color, #f5f5f5);
  --media-button-size: 40px;
  --media-button-icon-size: 80%;
  --media-button-padding: 0px;
  --media-button-border-radius: 8px;
  --media-button-hover-bg: rgb(255 255 255 / 0.2);
  --media-button-hover-transform: scale(1);
  --media-button-hover-transition: transform 0.2s ease-in;
  --media-button-touch-hover-border-radius: 100%;
  --media-button-touch-hover-bg: rgb(255 255 255 / 0.2);
}

.vds-button[data-active] .cc-off-icon,
.vds-button:not([data-active]) .cc-on-icon {
  display: none;
}