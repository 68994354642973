#topBanner {
  background: #fffbf5;
  padding: 50px 0px;
  position: relative;
  z-index: 1;
}
#topBanner::before {
  content: '';
  position: absolute;
  background-image: url('../../../../Images/page-banner-shape.png');
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  z-index: -1;
  top: 35%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  animation: off-on 10s infinite linear;
}
@keyframes off-on {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#topBanner .title {
  line-height: 39px;
  letter-spacing: 0.20000000298023224px;
}
#topBanner .subtitle {
  line-height: 27px;
  letter-spacing: 0.20000000298023224px;
  margin-bottom: 16px;
}
